import React, {Suspense} from "react";
import {useRoutes} from "react-router-dom";

//components
import Root from "./Root";


//home
const Home = React.lazy(() => import('../pages/Home'));

//landings
const Index1 = React.lazy(() => import('../pages/landings/Index1'));
const index2 = React.lazy(() => import('../pages/landings/Index2'));
const index3 = React.lazy(() => import('../pages/landings/Index3'));
const index4 = React.lazy(() => import('../pages/landings/Index4'));
const index5 = React.lazy(() => import('../pages/landings/Index5'));
const index6 = React.lazy(() => import('../pages/landings/Index6'));

const Terms = React.lazy(() => import('../pages/terms/Terms'));


//auth
// const Login = React.lazy(() => import('../pages/auth/Login'))
// const SignUp = React.lazy(() => import('../pages/auth/Signin'))

const loading = () => <div className=""></div>;

type LoadingComponentProps = {
    component: React.LazyExoticComponent<() => JSX.Element>;
};

const LoadComponent = ({component: Component}: LoadingComponentProps) => {
    return (
        <Suspense fallback={loading()}>
            <Component/>
        </Suspense>
    )
};

const AllRoutes = () => {
    return useRoutes([
        {
            //root route
            path: '/',
            element: <LoadComponent component={index2}/>,
        },
        {
            //public routes
            path: '/terms',
            element: <LoadComponent component={Terms}/>,
        }
    ])
}

export default AllRoutes;
